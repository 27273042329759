import { useEffect, useState, useCallback } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Bike, ChevronLeft, MapPin, Notebook, Phone, ShoppingBag, User, X } from 'lucide-react';
import Loader from '@/components/common/Loader';
import { Button } from '@/components/ui/button';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format, addDays, isSameDay, parse, isAfter } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from 'react-toastify';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import { SEO } from '@/components/common/SEO';
import axiosInstance from '../../utils/axiosInstance';
import { useUser } from '../../contexts/UserContext';

const timeSlots = ['10:00-12:00', '12:00-14:00', '14:00-16:00', '16:00-18:00', '18:00-20:00'];

const formatTimeSlot = (slot) => {
  const [start, end] = slot.split('-');
  return `${formatTime(start)} - ${formatTime(end)}`;
};

const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date(0, 0, 0, Number.parseInt(hours), Number.parseInt(minutes));
  return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
};

export default function HistoryDetails() {
  const [isAllSlotsOccupiedForToday, setIsAllSlotsOccupiedForToday] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canRequestCancellation, setCanRequestCancellation] = useState(true);
  const [canReschedule, setCanReschedule] = useState(true);
  const [tempOrder, setTempOrder] = useState(null);
  const { user } = useUser();

  const fetchOrderDetails = useCallback(async () => {
    const { id } = params;

    try {
      setLoading(true);

      const { data } = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/details?orderId=${id}`,
        {
          headers: {
            refreshToken: localStorage.getItem('refreshToken'),
            sessionToken: localStorage.getItem('sessionToken'),
          },
        }
      );

      setTempOrder(data);

      const currentTime = new Date();
      const orderDate = new Date(data.deliveryDate);
      const [startTime] = data.timeSlot ? data.timeSlot.split('-') : [];
      const slotStartTime = orderDate
        ? new Date(
            orderDate.setHours(
              Number.parseInt(startTime?.split(':')[0]),
              Number.parseInt(startTime?.split(':')[1]),
              0,
              0
            )
          )
        : null;

      setCanRequestCancellation(
        slotStartTime && currentTime < new Date(slotStartTime.getTime() - 3600000)
      );
      setCanReschedule(slotStartTime && currentTime < slotStartTime);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchOrderDetails();
  }, [user]);

  useEffect(() => {
    if (tempOrder) {
      setOrder(tempOrder);
      setTempOrder(null);
    }
  }, [tempOrder]);

  const handleCancelOrder = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/status?orderId=${
          params?.id
        }&status=Cancelled`,
        {
          cancellationReason: cancelReason,
        },
        {
          headers: {
            refreshToken: localStorage.getItem('refreshToken'),
            sessionToken: localStorage.getItem('sessionToken'),
          },
        }
      );
      toast.success('Order Cancelled Successfully');
      navigate('/');
    } catch (error) {
      console.error('Error cancelling order:', error);
      toast.error('Failed to cancel order');
    } finally {
      setLoading(false);
      setIsCancelModalOpen(false);
    }
  };

  const handleRescheduleOrder = async () => {
    const data = {
      date: format(rescheduleDate, 'yyyy-MM-dd'),
      selectedTimeSlot,
    };

    if (new Date(data.date).getDate() == new Date().getDate()) {
      const currentHour = new Date().getHours();
      const selectedHour = selectedTimeSlot.split('-')[0].split(':')[0];

      if (currentHour >= selectedHour) {
        toast.error('Slot Not Available! Please Choose Next Available Slot');
        return;
      }
    }
    try {
      setLoading(true);

      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/status?orderId=${
          params?.id
        }&status=Rescheduled`,
        {
          rescheduleDeliveryDate: format(rescheduleDate, 'yyyy-MM-dd'),
          rescheduleTimeSlot: selectedTimeSlot,
        },
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      toast.success('Order Rescheduled Successfully');
      navigate('/');
    } catch (error) {
      console.error('Error rescheduling order:', error);
      toast.error('Failed to reschedule order. Please try again.');
    } finally {
      setLoading(false);
      setIsRescheduleModalOpen(false);
    }
  };

  const isSlotOccupied = (slot) => {
    const [start] = slot.split('-');
    const slotTime = parse(start, 'HH:mm', new Date());
    const currentTime = new Date();

    if (isSameDay(rescheduleDate, new Date())) {
      return isAfter(currentTime, slotTime);
    }
    return false;
  };

  useEffect(() => {
    const allOccupied = timeSlots.every(isSlotOccupied);
    setIsAllSlotsOccupiedForToday(allOccupied);
  }, [rescheduleDate]); // Removed isSlotOccupied from dependencies

  const renderOrderStatus = (status) => {
    switch (status) {
      case 'Completed':
        return (
          <div className="flex items-center gap-1 text-green-500 text-sm">
            Delivered <span className="text-red-500">(Payment due)</span>
          </div>
        );
      case 'Pending':
        return <div className="flex items-center gap-1 text-yellow-500 text-sm">{status}</div>;
      case 'Cancelled':
        return <div className="flex items-center gap-1 text-red-500 text-sm">{status}</div>;
      case 'Paid':
        return <div className="flex items-center gap-1 text-blue-500 text-sm">{status}</div>;
      default:
        return <div className="flex items-center gap-1 text-amber-700 text-sm">{status}</div>;
    }
  };

  const cancelReasons = [
    'Out of Home',
    'Found a better price elsewhere',
    'Ordered by mistake',
    'Delivery time is too long',
    'Other',
  ];

  return (
    <div className=" bg-gray-50">
      <header className="sticky top-0 z-10 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              navigate(-1);
            }}
            className="h-8 w-8"
          >
            <ChevronLeft className="h-6 w-6 cursor-pointer" />
          </Button>
          <h1 className="text-lg font-medium">Order Details</h1>
          <Link to="/profile" className="transition-transform ml-auto hover:scale-105">
            <Avatar className="border-primary/10">
              <AvatarImage src="https://github.com" alt="@shadcn" />
              <AvatarFallback className="bg-blue-700/10  font-medium">
                {user?.name?.slice(0, 1) === '+' ? (
                  <User className="w-8" />
                ) : (
                  user?.name?.slice(0, 1) || <User className="w-8" />
                )}
              </AvatarFallback>
            </Avatar>
          </Link>
        </div>
      </header>
      {!tempOrder && loading && (
        <div className="fixed top-0 max-w-md h-full  w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      <SEO
        title="Order - Subziwale Your Trusted Partner for Fruits & Vegetables"
        description="Browse our fresh selection of fruits, vegetables, and dairy products. High-quality produce delivered to your doorstep in Dwarka, Delhi."
        canonicalUrl="https://www.subziwale.com/history/dfeferybbbjhbbhbh"
        ogImage="/images/leaf2.png"
      />
      {(order || tempOrder) && (
        <main className="mx-auto border-l pb-16 border-r  max-w-lg space-y-4 p-4">
          <section className="rounded-lg bg-white p-4">
            <div className="flex mb-3 items-center justify-between">
              <h2 className="font-medium">Order Items</h2>
              {/* <button
                onClick={handleModifyOrder}
                className="px-4 py-1 rounded-md bg-green-700 text-white"
              >
                Modify Order
              </button> */}
            </div>
            <div className="space-y-4">
              {(order || tempOrder).items.map((item) => (
                <div key={item.inventoryId} className="flex items-center gap-3">
                  <img
                    loading="lazy"
                    src={item.productImageUrl}
                    alt={item.productName}
                    className="rounded-md w-16 h-16 object-cover"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                    }}
                  />
                  <div className="flex-1">
                    <h3 className="font-medium text-sm">{item.productName}</h3>
                  </div>
                  <div className="text-right">
                    <p className="font-medium text-sm">
                      <span>
                        {item?.quantity} {item?.unit !== 'pc' && 'X'}
                      </span>{' '}
                      {item.variant} {item.unit}
                    </p>
                    <p className="text-xs text-gray-500">₹ {item.netPrice * item.quantity}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="rounded-lg bg-white p-4">
            <h2 className="text-base font-medium mb-4">Order details</h2>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600 flex items-center gap-1">
                    <Notebook className="h-4 w-4 inline-block" /> Items total
                  </span>
                  <span className="text-xs px-[6px] py-[.5px] bg-blue-50 rounded-full text-blue-600 font-medium">
                    Saved ₹{(order || tempOrder).discount.toFixed(2)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm line-through text-gray-400">
                    ₹{(order || tempOrder).mrp?.toFixed(2)}
                  </span>
                  <span className="text-sm font-medium">
                    ₹{((order || tempOrder).mrp - (order || tempOrder).discount)?.toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 flex items-center gap-1">
                  <Bike className="h-4 w-4 inline-block" /> Delivery charge
                </span>
                <span className="text-sm font-medium">
                  {(order || tempOrder).deliveryCharges > 0
                    ? `₹${(order || tempOrder).deliveryCharges?.toFixed(2)}`
                    : 'FREE'}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 flex items-center gap-1">
                  <ShoppingBag className="h-4 w-4 inline-block" />
                  Platform Fees
                </span>
                <span className="text-sm font-medium">
                  ₹
                  {(order || tempOrder).platformFees !== 0
                    ? (order || tempOrder).platformFees?.toFixed(2)
                    : 0}
                </span>
              </div>

              <div className="flex justify-between items-center pt-3 border-t">
                <span className="text-base font-medium">Bill total</span>
                <span className="text-base font-medium">
                  ₹{(order || tempOrder).total?.toFixed(2)}
                </span>
              </div>
            </div>
          </section>

          <section className="rounded-lg bg-white p-4">
            <h2 className="mb-4 font-medium">Delivery Details</h2>
            <div className="space-y-3">
              <div className="flex gap-3">
                <MapPin className="h-5 w-5 shrink-0 text-gray-900" />
                <p className="text-sm text-gray-600">
                  {(order || tempOrder)?.deliveryAddress || 'Not Provided'}
                </p>
              </div>
              <div className="flex gap-3">
                <Phone
                  className="h-5 w-5 shrink-0 cursor-pointer text-gray-900"
                  onClick={() => {
                    window.open(`tel:${(order || tempOrder)?.contactNo}`);
                  }}
                />
                <p className="text-sm text-gray-600">
                  {(order || tempOrder)?.contactNo || 'Not Provided'}
                </p>
              </div>
            </div>
          </section>

          <section className="rounded-lg bg-white p-4">
            <h2 className="mb-1 font-medium">Order Status</h2>
            <p className="text-sm flex items-center gap-2 text-gray-600">
              Status: {renderOrderStatus((order || tempOrder).status)}
            </p>
            <p className="text-sm text-gray-600">
              Date:{' '}
              <span className="font-medium">
                {format(new Date((order || tempOrder).deliveryDate), 'PPP')}
              </span>
            </p>
            <p className="text-sm text-gray-600">
              Time Slot:{' '}
              <span className="font-medium">
                {!(order || tempOrder)?.expressDelivery
                  ? (order || tempOrder)?.timeSlot
                    ? formatTimeSlot((order || tempOrder)?.timeSlot)
                    : 'Not Given'
                  : (order || tempOrder)?.timeSlot}
              </span>
            </p>
            {(order || tempOrder)?.note && (
              <p className="text-sm text-gray-600">
                Note: <span className="font-medium">{(order || tempOrder)?.note}</span>
              </p>
            )}
            <p className="text-sm text-gray-600">
              Delivery Type:{' '}
              <span className="font-medium">
                {(order || tempOrder).selfPickup
                  ? 'Self Pickup'
                  : (order || tempOrder).expressDelivery
                    ? 'Express Delivery'
                    : 'Standard Delivery'}
              </span>
            </p>
          </section>

          <footer
            className={`bg-white rounded-md px-4 py-4 ${
              (order || tempOrder)?.status === 'Paid' && 'hidden'
            }`}
          >
            <div className="">
              {(order || tempOrder)?.expressDelivery ? (
                <div>{/* Buy Again button can be added here if needed */}</div>
              ) : (
                <div className="w-full gap-2 flex items-center justify-center">
                  {canRequestCancellation &&
                    ((order || tempOrder)?.status === 'Pending' ||
                      (order || tempOrder)?.status === 'Rescheduled') && (
                      <Button
                        className="bg-red-600 w-1/3 hover:bg-red-500"
                        onClick={() => setIsCancelModalOpen(true)}
                      >
                        Cancel Order
                      </Button>
                    )}
                  {canReschedule &&
                    ((order || tempOrder)?.status === 'Pending' ||
                      (order || tempOrder)?.status === 'Rescheduled') && (
                      <Button
                        className="bg-blue-600 w-1/3  hover:bg-blue-700 focus-visible:ring-offset-0 focus-visible:ring-1"
                        onClick={() => setIsRescheduleModalOpen(true)}
                      >
                        Reschedule
                      </Button>
                    )}
                  {/* Buy Again button can be added here if needed */}
                </div>
              )}
            </div>
            {!canRequestCancellation &&
              !canReschedule &&
              ((order || tempOrder)?.status === 'Pending' ||
                (order || tempOrder)?.status === 'Rescheduled') && (
                <p className="text-sm text-balance text-red-500 mt-2 text-center">
                  Cancellation and rescheduling are no longer available for this order.
                </p>
              )}
          </footer>
        </main>
      )}
      <BottomNav />

      {isCancelModalOpen && (
        <div className="fixed inset-0 max-w-sm mx-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Cancel Order</h2>
              <Button variant="ghost" size="icon" onClick={() => setIsCancelModalOpen(false)}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <p className="mb-4 text-sm text-gray-600">
              Please select a reason for cancelling this order:
            </p>
            <RadioGroup onValueChange={setCancelReason} className="space-y-2">
              {cancelReasons.map((reason) => (
                <div key={reason} className="flex items-center space-x-2">
                  <RadioGroupItem value={reason} id={reason} />
                  <Label htmlFor={reason} className="cursor-pointer">
                    {reason}
                  </Label>
                </div>
              ))}
            </RadioGroup>
            <div className="mt-6 flex justify-end">
              <Button
                variant="outline"
                onClick={() => setIsCancelModalOpen(false)}
                className="mr-2"
              >
                Close
              </Button>
              <Button
                className="focus-visible:ring-offset-0 focus-visible:ring-0  text-white bg-red-600 hover:bg-red-500"
                onClick={handleCancelOrder}
              >
                Cancel Order
              </Button>
            </div>
          </div>
        </div>
      )}

      {isRescheduleModalOpen && (
        <div className="fixed inset-0 z-50 max-w-sm mx-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Reschedule Order</h2>
              <Button variant="ghost" size="icon" onClick={() => setIsRescheduleModalOpen(false)}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <p className="mb-4 text-sm text-gray-600">Please select a new date for your order:</p>
            <div className="flex justify-between mb-4">
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, new Date())
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(new Date())}
                disabled={isAllSlotsOccupiedForToday}
              >
                Today
              </Button>
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, addDays(new Date(), 1))
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(addDays(new Date(), 1))}
              >
                {format(addDays(new Date(), 1), 'EEE, MMM d')}
              </Button>
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, addDays(new Date(), 2))
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(addDays(new Date(), 2))}
              >
                {format(addDays(new Date(), 2), 'EEE, MMM d')}
              </Button>
            </div>
            <div className="mt-4">
              <label htmlFor="timeSlot" className="block text-sm font-medium text-gray-700 mb-1">
                Select Time Slot
              </label>
              <Select
                onValueChange={setSelectedTimeSlot}
                value={selectedTimeSlot}
                className="focus-visible:ring-offset-0 focus-visible:ring-1"
              >
                <SelectTrigger
                  id="timeSlot"
                  className="focus-visible:ring-offset-0 focus-visible:ring-1"
                >
                  <SelectValue placeholder="Select a time slot" />
                </SelectTrigger>
                <SelectContent className="focus-visible:ring-offset-0 focus-visible:ring-1">
                  {timeSlots.map((slot) => (
                    <SelectItem key={slot} value={slot} disabled={isSlotOccupied(slot)}>
                      {formatTimeSlot(slot)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                variant="outline"
                onClick={() => setIsRescheduleModalOpen(false)}
                className="mr-2"
              >
                Close
              </Button>
              <Button
                className="focus-visible:ring-offset-0 focus-visible:ring-0 text-white bg-green-600 hover:bg-green-500"
                onClick={handleRescheduleOrder}
                disabled={!selectedTimeSlot || loading}
              >
                {loading ? 'Rescheduling...' : 'Reschedule'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
